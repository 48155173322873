<template>
  <div class="app-container">
    <div class="our_prent">
      <span>{{ $t('m.navbar.china_cooperate_text') }}</span>
    </div>
    <table style="width: 1200px;">
      <tbody>
        <tr>
          <td style="vertical-align: top;">
            <div class="z_contts">
              <div class="wrapper-content">
                <!-- 顶部输入框 -->
                <div class="search_form">
                  <!--<div class="ssuo">-->
                  <!--<span style="color: #333; font-weight: bold;">需求名称：</span>-->
                  <!--<input type="text" class="jsmcheng" v-model="listQuery.demandName">-->
                  <!--<span style="color: #333; font-weight: bold;">发布单位：</span>-->
                  <!--<input type="text" class="jsmcheng" v-model="listQuery.pubEnt">-->
                  <!--<input type="button" value="搜索" class="ssou_but" @click="search">-->
                  <!--</div>-->
                  <div class="ssuo">
                    <!-- 需求名称 -->
                    <span style="color: #333; font-weight: bold;">{{ $t('m.hezuo.keyword_text') }}</span>
                    <input
                      :style="{width: this.$language === 'cn' ? '340px' : '290px'}"
                      type="text"
                      class="jsmcheng"
                      v-model="listQuery.demandName"
                    >
                    <!-- 发布单位 -->
                    <!--<span style="color: #333; font-weight: bold;">{{ $t('m.hezuo.organization_text') }}</span>-->
                    <!--<input-->
                      <!--:style="{width: this.$language === 'cn' ? '340px' : '290px'}"-->
                      <!--type="text"-->
                      <!--class="jsmcheng"-->
                      <!--v-model="listQuery.pubEnt"-->
                    <!--&gt;-->
                    <!-- 搜索 -->
                    <input v-if="this.$language === 'cn'" type="button" value="搜索" class="ssou_but" @click="search">
                    <input v-else type="button" value="Search" class="ssou_but" @click="search">
                  </div>
                </div>
                <!-- 行业 -->
                <industry-box
                  v-if="this.$language === 'cn'"
                  @handleAllIndustry="handleAllIndustry"
                  @handleIndustryFirstChange="handleIndustryFirstChange"
                  @handleIndustrySecondChange="handleIndustrySecondChange"
                />
                <industry-box-en
                  v-else
                  @handleAllIndustry="handleAllIndustry"
                  @handleIndustryFirstChange="handleIndustryFirstChange"
                  @handleIndustrySecondChange="handleIndustrySecondChange"
                />
                <!-- 国家 -->
                <!--<country-box-->
                <!--:country="listQuery.nation"-->
                <!--@handleCountryChange="handleCountryChange"-->
                <!--/>-->
              </div>
              <div class="isreal_leftcont">
                <table class="comm_visits">
                  <tbody>
                  <tr
                    v-for="item in list"
                    :key="item.id"
                  >
                    <td>
                      <ol>
                        <li>
                            <div class="title_box">
                              <a @click="handleItemClick(item)">
                                {{ item.demandName }}
                              </a>
                            </div>
                        </li>
                      </ol>
                    </td>
                    <td>
                      <span style="float: right; margin-right: 5px;">[{{ item.publishTime }}]</span>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <div style="margin-top: 30px; background-color: #dddbdb; height: 1px;" />
              <div class="pagination-container" style="margin-top: 30px;">
                <el-pagination
                  :page-size="listQuery.pageSize"
                  :current-page.sync="listQuery.pageNum"
                  :total="total"
                  background
                  layout="total, prev, pager, next, jumper"
                  @current-change="handleCurrentChange"
                />
              </div>
            </div>
          </td>
          <td style="vertical-align: top;">
            <menu-box :active-index="activeType" />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
//
import { cceecDemandList } from '@/api/cceec/cooperate'

import CountryBox from '../components/countrybox'
import IndustryBox from '../components/industrybox'
import IndustryBoxEn from '../components/industryboxen2'
import MenuBox from '../components/menubox'

const defaultListQuery = {
  demandName: '', //  需求名称
  pubEnt: '', //  发布单位
  nation: 1,
  demandType: null,
  pageNum: 1,
  pageSize: 20
}
export default {
  name: 'CooperateCEEC',
  components: {
    MenuBox,
    CountryBox,
    IndustryBox,
    IndustryBoxEn
  },
  data() {
    return {
      activeType: '2',
      listQuery: Object.assign({}, defaultListQuery),
      loading: false,
      list: [],
      total: 0
    }
  },
  created() {
    this.listQuery = Object.assign({
      languageType: this.$languageType
    }, this.listQuery)
    this.getList()
  },
  methods: {
    getList() {
      this.loading = true
      cceecDemandList(this.listQuery).then(res => {
        this.loading = false
        console.log(res)
        this.list = res.data.list
        this.total = res.data.total
      }).catch(() => {
        this.loading = false
      })
    },
    //  行业改变
    //  全部
    handleAllIndustry() {
      this.listQuery.demandType = null
      this.search()
    },
    //  第一级
    handleIndustryFirstChange(val) {
      this.listQuery.demandType = null
      this.listQuery.demandType = 'A' + val + 'A'
      this.search()
    },
    //  第二级
    handleIndustrySecondChange(first, second) {
      this.listQuery.demandType = null
      this.listQuery.demandType = 'A' + first + 'AB' + second + 'B'
      this.search()
    },
    //  国家改变
    handleCountryChange(val) {
      this.listQuery.nation = val
      this.search()
    },
    //  搜索
    search() {
      console.log(this.listQuery)
      this.getList()
    },
    //  点击
    handleItemClick(item) {
      this.$router.push({ path: '/cooperate/details', query: {id: item.id, type: this.activeType}})
    },
    //  页码改变
    handleCurrentChange(val) {
      this.listQuery.pageNum = val
      this.search()
    }
  }
}
</script>

<style lang="scss" scoped>
  table {
    tr {
      margin: 0;
      padding: 0;
      height: 60px;
    }
    td {
      padding: 0;
    }
  }
  input {
    padding: 0;
    color: #666666;
    outline: medium;
  }
  .z_contts {
    width: 950px;
    /*min-height: 500px;*/
    line-height: 30px;
    /*border: 1px solid red;*/
    .wrapper-content {
      margin-top: 10px;
      padding-top: 0px !important;
    }
  }
  .search_form {
    .ssuo {
      width: 950px;
      height: 45px;
      line-height: 45px;
      border: 1px #ccc dashed;
      span {
        font-size: 14px;
        margin-left: 10px;
      }
      .jsmcheng {
        width: 340px;
        height: 21px;
        margin-right: 5px;
        padding-left: 10px;
      }
      .ssou_but {
        width: 60px;
        height: 25px;
        color: #fff;
        line-height: 25px;
        text-align: center;
        outline: none;
        border: none;
        cursor: pointer;
        background-color: #465364;
      }
    }
  }
  .isreal_leftcont {
    margin-top: 20px;
    width: 950px;
    font-size: 14px !important;
    .comm_visits {
      width: 950px;
      margin-top: 25px;
      tr {
        height: 35px !important;
      }
      ol {
        padding: 0;
        margin: 0 0 0 20px;
        list-style: disc;
      }
    }
  }
  .title_box {
    margin-left: 5px;
    font-size: 14px;
    width: 650px;
    cursor: pointer;
    color: #656565;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  }
</style>
